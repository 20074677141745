import { FluentApiEndpoint } from '@tvaliasek/fluent-axios-api-client'

export class Sessions extends FluentApiEndpoint {
    async saveDocumentData (documentData) {
        return await this.doPatchRequest(`/${this.url}/current/document-data`, { documentData })
    }

    async evaluateInvestmentParameters () {
        return await this.doGetRequest(`/${this.url}/current/investment-parameters`)
    }

    async toggleSigneable (isSigneable) {
        return await this.doPatchRequest(`/${this.url}/current`, { isSigneable: !!isSigneable })
    }

    async isSigneable () {
        return await this.doHeadRequest(`/${this.url}/current`)
    }

    async submitDocument (documentData) {
        await this.saveDocumentData(documentData)
        return await this.toggleSigneable(true)
    }

    async sendSignatureSms (locale) {
        return await this.doPostRequest(`/${this.url}/current/signature`, { locale })
    }

    async validateSignatureSms (code) {
        return await this.doPatchRequest(`/${this.url}/current/signature`, { code })
    }
}
