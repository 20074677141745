import Router from 'vue-router'
import ComponentLoader from '../../Components/ComponentLoader.vue'
import ComponentError from '../../Components/ComponentError.vue'
import { HeadRequestGuard } from './HeadRequestGuard.js'
const loaderSettings = {
    loading: ComponentLoader,
    error: ComponentError,
    delay: 10,
    timeout: 30 * 1000
}

const createRouter = (appGuard = null) => {
    return new Router({
        mode: 'history',
        base: '/',
        routes: [
            {
                path: '',
                name: 'Index',
                component: () => import(/* webpackChunkName: "index" */ '../../Views/Index/Index.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnterLogin()
            },
            {
                path: '/app/invalid-session',
                name: 'InvalidSession',
                component () {
                    return {
                        component: import(/* webpackChunkName: "appInvalidSession" */ '../../Views/App/Views/InvalidSession/InvalidSession.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: '/app/invalid-signature-session',
                name: 'InvalidSignatureSession',
                component () {
                    return {
                        component: import(/* webpackChunkName: "appInvalidSignatureSession" */ '../../Views/App/Views/InvalidSignatureSession/InvalidSignatureSession.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: '/app/signature-saved',
                name: 'SignatureSaved',
                component () {
                    return {
                        component: import(/* webpackChunkName: "appSignatureSaved" */ '../../Views/App/Views/SignatureSaved/SignatureSaved.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: '/app',
                component: () => import(/* webpackChunkName: "landing" */ '../../Views/App/Landing.vue'),
                ...loaderSettings,
                children: [
                    {
                        path: '',
                        name: 'Landing',
                        beforeEnter: appGuard.beforeEnter('vip'),
                        component () {
                            return {
                                component: import(/* webpackChunkName: "appLanding" */ '../../Views/App/Views/Index/Index.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'signature',
                        name: 'Signature',
                        beforeEnter: appGuard.beforeEnter('vip'),
                        component () {
                            return {
                                component: import(/* webpackChunkName: "appSignature" */ '../../Views/App/Views/Signature/Signature.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'remote-signature',
                        name: 'RemoteSignature',
                        beforeEnter: appGuard.beforeEnter('signature'),
                        component () {
                            return {
                                component: import(/* webpackChunkName: "appRemoteSignature" */ '../../Views/App/Views/RemoteSignature/RemoteSignature.vue'),
                                ...loaderSettings
                            }
                        }
                    }
                ]
            }
        ]
    })
}

export class RouterFactory {
    constructor (appSettings) {
        this.appSettings = appSettings
        this.appGuard = new HeadRequestGuard(this.appSettings.APP_GUARD_URL, { name: 'Index' })
        this._router = createRouter(this.appGuard)
    }

    get router () {
        return this._router
    }
}
